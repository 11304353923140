import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet';
import "@fortawesome/fontawesome-pro/css/all.css"
import ImageZoom from 'react-medium-image-zoom'


import Layout from '../../components/layout'
import Header from '../../components/header'
import ReturnToTop from '../../components/returnToTop';
import JumpLinks from '../../components/portfolioJumpLink'


import ImagePersonaPresenter from '../../images/prism/prism-persona1.png';
import ImagePersonaOperator from '../../images/prism/prism-persona2.png';
import ImageProcess from '../../images/prism/prism-process.png';
import ImageIA from '../../images/prism/prism-ia.png';


import ImageMockup1 from '../../images/prism/prism-mockup-1.jpg';
import ImageMockup2 from '../../images/prism/prism-mockup-2.jpg';
import ImageMockup3 from '../../images/prism/prism-mockup-3.jpg';
import ImageMockup4 from '../../images/prism/prism-mockup-4.jpg';
import ImageMockup5 from '../../images/prism/prism-mockup-5.jpg';
import ImageMockup6 from '../../images/prism/prism-mockup-6.jpg';
import ImageMockup7 from '../../images/prism/prism-mockup-7.jpg';

import ImageTaskAnalysis from '../../images/prism/prism-taskanalysis.jpg';
import ImageWhiteboard from '../../images/prism/prism-whiteboard.jpg';


import ImageMockupGroup from '../../images/prism/prism-mockup-drafts.png';
import ImageProduct1 from '../../images/prism/prism-product-1.jpg';
import ImageProduct2 from '../../images/prism/prism-product-2.jpg';
import ImageProduct3 from '../../images/prism/prism-product-3.jpg';


import ImageApp from '../../images/prism/prism-final.jpg';




const Portfolio1 = ({data}) => (
  <Layout>
      <main className="page--portfolio portfolio--prism">
      <Helmet>
          <title>{data.site.siteMetadata.assessTitle} | {data.site.siteMetadata.title}</title>
      </Helmet>

        <section className="slab slab--hero" name="hero">
          <Header siteTitle="Rob DiMarzo" /> 

          <div className="slab__inner">
            <div class="pageblock--title">
              <h1>
                {data.site.siteMetadata.assessTitle}
              </h1>
              <span>
                {data.site.siteMetadata.assessDesc}
              </span>
              <JumpLinks/>
            </div>
          </div>  
        </section>

        <section className="slab slab--notification">
          <div className="slab__inner">
            <p>
              Due to the sensitive and proprietary nature of this product, certain information has been withheld and modified. This product will be referred to as <strong>Prism</strong> and the client as <strong>Kappa</strong>.
            </p>
          </div>
        </section>
        
        
        <section className="slab slab--intro" name="intro">
          <div className="slab__overlay"></div>
          <div className="slab__inner">
            <div className="pageblock--title">
              <h2>Project Backstory</h2>
              <div className="title__line"></div>
            </div>
            <div className="pageblock">
              <div className="pageblock__content">
                <p>Big corporations want to become more tax efficient to increase their long-term sustainability and revenue. Without in-house tax knowledge, they turn to corporate tax specialists to guide them towards the right strategy. 
                </p>
                  <p>
                  In order to determine the right tax strategy, it’s essential for the tax specialists to assess the company's current state of affairs and understand their overall business goals. With this information, opportunities can be identified among the company’s strengths and weaknesses to create an action plan curated to their needs.</p>            
                
                <p>To do this, lead partners at Kappa meet with the company’s leadership for a workshop to discuss reimagine their tax strategy and discuss their business needs. Our goal was to build a tool that augments the facilitation of these workshops and enables new business engagement. </p>              
              </div>
            </div>

          </div>
        </section>
      
        <section className="slab slab--problem" name="goals-challenges" >
          <div className="slab__inner">

            <div className="pageblock--title">  
                <h2>Identifying the goals & challenges</h2>
                <div className="title__line"></div>
                <p>
                  I conducted interviews with the tax professionals who run these workshops to better understand their process, the workshop goals, and the dynamics between the parties in order to empathize with their needs.       
                </p>
            </div>

            <div className="pageblock">
                <i class="fal fa-users-crown"></i>
                <h3>
                  A Leader in Technology
                </h3>
                <div className="pageblock__content">
                  <p>
                  The workshops until this point were performed with whiteboards and sticky notes, which failed to demonstrate our technological capabilities. By facilitating these workshops using an collaborative presentation tool, it communicates a message to our potential clients that we are a leader in innovative technology.
                  </p>
                </div>
            </div>
            
            <div className="pageblock">
              <i class="fal fa-check-double"></i>
              <h3>
                Efficiency and Ease of Use
              </h3>
              <div className="pageblock__content">
                <p>
                  When designing a product to assist in an existing process, it’s crucial that the product itself doesn’t become a source of frustration. Efficiency became a guiding design principle to ensure usability, prevent friction, and ensure adoption.                
                  </p>
              </div>
            </div>

            <div className="pageblock">
              <i class="fal fa-dice-d6"></i>
              <h3>
                Digitizing an Analog Process
              </h3>
              <div className="pageblock__content">
                <p>
                The current process lacked organization and insight, so we did not want to replicate it outright. We analyzed the business process, workshop dynamics, and information architecture to reimagine the user experience and craft a meaningful interactive tool specialized to the content, message, and audience. 

                </p>
              </div>
            </div>

            <div className="pageblock">
              <i class="fal fa-wand-magic"></i>
              <h3>
                The Magic of Showmanship

              </h3>
              <div className="pageblock__content">
                <p>
                Understanding the dynamics within the meeting room challenged us to create a tool that could be operated in real-time with ease. It was vital for the Presenter to lead the conversation and engage the participants, while another person in the room captures notes and operates the tool. 
                </p>
              </div>  
            </div>
          </div>
        </section>

        <section className="slab slab--solution" name="my-role">
          <div className="slab__inner">
            <div className="pageblock--title">
                <h2>My role in the product</h2>
                <div className="title__line"></div>
                <div className="pageblock__content">
                  <p>
                    I was the <strong>Lead UX Researcher, UX Designer, and UX Engineer </strong> on this project, providing end-to-end UX services - from strategy and research, to design and development. I worked alongside 6 Users, 2 Full-stack Developers, 1 UX Director,and 1 Business Analyst throughout this process. I was personally responsible for the following:
                  </p>
                </div>
            </div>

            <div className="pageblock">
              <i class="fal fa-user-chart"></i>
              <h3>
                User Research
              </h3>
              <div class="pageblock__content">
                <p>
                  By interviewing tax leaders who have facilitated or aided in these workshops, I was able to understand their processes, goals, and needs before, during, and after an assessment - as well as the frictions they currently face. I also focused on the dynamics within the meeting room and the expectations of the client participants.  
                  </p>
              </div>
            </div>

            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImagePersonaPresenter,
                    alt: 'Persona of Presenter',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImagePersonaPresenter,
                    alt: 'Persona of Presenter',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                A sample persona for the Presenter - the user presenting the insights that the Operator has captured.
              </figcaption>
            </figure>

            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImagePersonaOperator,
                    alt: 'Persona of operator',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImagePersonaOperator,
                    alt: 'Persona of operator',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                  A sample persona for the Operator - the user interacting with the tool to collect and connect key details in the conversation.
              </figcaption>
            </figure>


            <div className="pageblock">
                <i class="fal fa-head-side-brain"></i>
                <h3>
                  Design Thinking
                </h3>
                <div class="pageblock__content">
                  <p>
                    I validated and analyzed my research findings to outline the existing process and discover opportunities to enhance it through the introduction of a digital tool. 
                  </p>
                  <p>
                    I orchestrated a <em>Wizard of Oz</em> strategy, in which an Operator and Presenter work together to bring the experience to life. As with the existing process, the Presenter guides the conversation to solicit answers from the Participants. While this happens, an Operator uses the tool to capture and configure key talking points through an efficient “Operator Panel”. The data gets published in real-time to a polished “Presentation View” visible to the audience for the Presenter to interact with and further guide the workshop. 
                  </p>
                </div>
            </div>

            <figure className="pageblock--image is--full"> 
              <ImageZoom
                  image={{
                    src: ImageProcess,
                    alt: 'Journey map of proposed process',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageProcess,
                    alt: 'Journey map of proposed process',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                  A journey map used to validate the proposed flow for the workshop. This journey also includes the events leading up to and following the workshop to help contextualize it within the larger business process.
              </figcaption>
            </figure>


            <div className="pageblock">
              <i class="fal fa-sitemap"></i>
              <h3>
                Information Architecture
              </h3>
              <div className="pageblock__content">
                <p>
                  The workshop follows a framework of proprietary knowledge to swiftly identify a company’s operational strengths and weaknesses, connect their corporate strategies, and advise them on ways to improve. 
                </p>
                <p>
                  To ensure these concepts were built into the design, I mapped out their relationships to each other and their significance to the presentation. After validation, these findings became essential to the layout and navigation.
                </p>
              </div>
            </div>


            <figure className="pageblock--image is--full"> 
              <ImageZoom
                  image={{
                    src: ImageIA,
                    alt: 'IA diagram relating data concepts',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageIA,
                    alt: 'IA diagram relating data concepts',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                  A diagram that organizes the framework's main concepts and visualizes the relationships between them. 
              </figcaption>
            </figure>



            <div className="pageblock">
              <i class="fal fa-sticky-note"></i>
              <h3>
                Task Analysis
              </h3>
              <div className="pageblock__content">
                <p>
                  To design separate interfaces for both Presenters and Operators, the boundaries of their needs and responsibilities had to be established. We compiled all the possible tasks a user might take within the product in order to validate the usage of each task for each user. 
                </p>
                <p>
                  With this knowledge, I was able to design UIs that prioritized highly important and frequently used tasks, excluded unneeded functionality, and organized the user experience to be simple, meaningful, and efficient for each user.
                </p>
              </div>
            </div>

            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImageTaskAnalysis,
                    alt: 'Task analysis document',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageTaskAnalysis,
                    alt: 'Task analysis document',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                Part of the Task Analysis document - cataloging all tasks with their importance and frequency per role (deduced by user consensus).
              </figcaption>
            </figure>

            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImageWhiteboard,
                    alt: 'Sample whiteboard images',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageWhiteboard,
                    alt: '`Sample whiteboard images',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                  Sample whiteboarding images from the project during the ideation phase - exploring UI layout and visualization options.
              </figcaption>
            </figure>


          <div className="pageblock">
                <i class="fal fa-object-group"></i>
                <h3>
                  Wireframes & Prototyping
                </h3>
                <div className="pageblock__content">
                  <p>
                    Iterating from low-fidelity to high-fidelity, I designed and presented UI mockups and interactive prototypes for continual validation from users, stakeholders, and engineers.
                  </p>
                  <p>
                    At the onset of this project, users imagined this would be a survey tool. As I presented ideas, I was able to visualize and suggest alternative design concepts based on the user research. Through validation of the mockups, the user's perception quickly evolved from a survey into an interactive presentation with seperate Presenter and Operator UIs. 
                  </p>
                  <p>
                    I built interactive prototypes to demonstrate and validate the flow of this process. As the designs became increasingly higher fidelity, I was able to explore a number of design directions, test their implications, and collect feedback from users. The feedback brought the product ever-closer to a "cutting edge" and "high-tech" look-and-feel, eventually leading to the final UI.
                  </p>
                  <p><u>Tools used for design:</u></p> 
                  <ul className="content__skills">
                    <li>Whiteboards, Pen & Paper</li>
                    <li>Sketch</li>
                    <li>Invision</li>
                    <li>HTML, CSS, JavaScript</li>
                  </ul>
                </div>
            </div>

            <figure className="pageblock--image is--full"> 
              <ImageZoom
                  image={{
                    src: ImageMockupGroup,
                    alt: 'Initial wireframe of tool',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageMockupGroup,
                    alt: 'Initial wireframe of tool',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                Driven by user feedback and testing, this collection of UI designs show a progression (from top-left to bottom-right) that became increasingly more meaningful and higher fidelity. 
              </figcaption>
            </figure>







            <div className="pageblock">
              <i class="fal fa-code"></i>
              <h3>
                UX Engineering
              </h3>
              <div className="pageblock__content">
                <p>
                Due to scheduling conflicts, I did not join the dev team until a majority of the app was built. The dev team was primarily focused on functionality and, without a UX Engineer in their ranks, built a UI that lacked the refinement of the final mockups. The front-end code was unsemantic, unresponsive, inconsistent, and unnecessarily reliant on 3rd party libraries for basic functionality. 
                </p>
                <p>
                  After a thorough analysis of the existing Angular app, I refactored every UI component. Most of my work was in restructuring the HTML layout to use CSS Flexbox and Grid, developing an SVG-based hexagon layout, reauthoring the stylesheets with SCSS and BEM, utilizing semantic and accessible HTML elements, and implementing web animations.
                </p>
                <p><u>Skills used for development:</u></p> 
                  <ul className="content__skills">
                    <li>HTML</li>
                    <li>CSS (SCSS)</li>
                    <li>SVG</li>
                    <li>JavaScript</li>
                    <li>Angular</li>
                  </ul>
              </div>
            </div>
            <figure className="pageblock--image is--full"> 
              <ImageZoom
                  image={{
                    src: ImageProduct1,
                    alt: 'Screenshot of Prism - State of Business',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageProduct1,
                    alt: 'Screenshot of Prism - State of Business',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                  A screenshot of the Business interface, where the state of the client's business is captured and presented in a hexagonal SVG layout. In this image, the Presenter is filtering by Tax Strategy. 
              </figcaption>
            </figure>


            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImageProduct2,
                    alt: 'Screenshot of Prism - Action Plan',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageProduct2,
                    alt: 'Screenshot of Prism - Action Plan',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                  A screenshot of the Action Plan interface, where the users identify next steps and grade them by Effort and Impact.
              </figcaption>
            </figure>

            <figure className="pageblock--image is--half"> 
              <ImageZoom
                  image={{
                    src: ImageProduct3,
                    alt: 'Screenshot of Prism - Split View',
                    className: 'img'
                  }}
                  zoomImage={{
                    src: ImageProduct3,
                    alt: 'Screenshot of Prism - Split View',
                    className: 'img--zoomed'
                  }}
                />
              <figcaption>
                  A screenshot of the Split view, allowing participants to relate both interfaces side-by-side. In this image, the Presenter is using the annotation feature as a story-telling device. 
              </figcaption>
            </figure>



          </div>
        </section>  
      </main>
      <ReturnToTop/>
  </Layout>
)

export const query = graphql`
  query AccessQuery {
    site {
      siteMetadata {
        title
        assessTitle
        assessDesc
      }
    }
  }
`
export default Portfolio1
